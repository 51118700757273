/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
/*    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
/*    monospace;*/
/*}*/
body{
    margin: 0;
}
.footer-main{
    /*position: absolute;*/
    height: 77px;
    width: 100%;
    /*background: #25345c;*/
    bottom: 0;
}
.border-bottom{
border-bottom: 1px solid #E2E5ED !important;
}
