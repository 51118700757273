// Import Colors File
@import "../../../assets/scss/colors";
@import "../../../assets/scss/common";

.dark-text-large {

}
.appt-table {
  max-width: 700px;
  margin: 20px auto;
  width: 100%;
  .schedule-row {

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .schedule-left {
    text-align: left;
    .main-head-text {
      text-align: left;
    }
    .light-text-medium {

    }
  }
  .schedule-right {
    .next-arrow-btn {
      background: transparent;
      border: none;
      transform: rotate(180deg);
      box-shadow: none;
    }
  }
}
.main-dialog .MuiDialog-paperScrollPaper{
  text-align: center;
}

.days-list{
  width: max-content !important;
  margin: 0 auto !important;
  .day-name{
    width: 93px;
  }
}
.time-Picker-wrapper{
 width: max-content;
  margin: 0 auto;
  text-align:left;
}

.no-result{
  display: flex;
  justify-content: center;
  .no-result-text{
    color: $title_black;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
  }

}
