.patients-section {

.date {
    font-size: 12px;
    padding: 0 20px 0 0;
}

.map-btn-wrap {
    margin: 25px auto 0;
    display: flex;
}

.rx-heading {
    margin-bottom: 0;
}

.patients-section {

}

.date {
    font-size: 12px;
    padding: 0 20px 0 0;
}

.green-tag {
    background: #EFF8E2;
    color: #8CBA4F;
    border-radius: 20px;
    padding: 0 10px 0 10px;
    font-size: 14px;
}

.red-tag {
    background: #F2BCC3;
    color: #E98B96;
    border-radius: 20px;
    padding: 0 10px 0 10px;
    font-size: 14px;
}

.baseColor {
    color: $light-blue2;
}


.flag {
    height: 17px;
    width: 17px;
    background: #0EB600;
    display: block;
    float: left;
    border-radius: 50%;
    margin: 0 6px 0 0;
}

.field-wrapper {
    margin-bottom: 15px !important;
}

}
