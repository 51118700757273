@import "../../../assets/scss/_colors.scss";

.main-dialog {
  .MuiDialog-paperWidthSm {
    min-width: 700px;
    min-height:400px;
  }
  .MuiDialog-paper {
    display: flex;
    position: relative;
    margin: 0;
    overflow-y: unset;
    padding-bottom: 5px;
  }
  .MuiDialog-paperScrollPaper {
    max-height:100% !important;
    padding: 20px;
  }
  .table-main {
    background-color: $body-bg;
    height: 400px;
    table {
      width: 100%;
      //padding: 10px 30px 10px 30px;
      border-collapse: separate;
      border-spacing: 0 20px;
    }

    .table-head{
      border-bottom: 2px solid #EBEBEB;
      padding-bottom: 8px;
      margin-right: 8px;
    }


    tr{
      display: flex;
      margin-bottom: 10px;
      justify-content: space-around;
      padding:0 30px;
    }
    th {
      color: grey;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
    .sec-th{
      text-align: center;
    }
    .sec-td{
      text-align: right;
    }
    td {
      width:62%;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $medium-text;
      text-align: left;
      word-break: break-word;
      button {
        width: 111px;
        background-color: white;
        margin-left: 5px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $medium-text;
        //height: 38px;
        padding: 8px 18px;
        border-radius: 4px;
        border: 2px solid #EBEBEB;
      }
      .MuiSelect-root {
        border-radius: 5px;
        background-color: white;
        padding: 8px 18px;
        border: 2px solid #EBEBEB;

        :focus {
          background: transparent;
        }
      }
      .MuiSelect-selectMenu {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $medium-text;
        padding: 0 20px 0 0;
        width: 60px;
        text-align: left;
      }
      .MuiInput-underline:after {
        border-bottom: 0 !important;
      }
      .MuiInput-underline:before {
        border-bottom: 0 !important;
      }
      .MuiInput-underline:hover {
        border-bottom: 0 !important;
      }
      svg.MuiSvgIcon-root.MuiSelect-icon {
        background: url("../../../assets/images/dropdownarrow.svg") no-repeat;
        width: 6px;
        height: 10px;
        padding-right: 14px;

        path {
          display: none;
        }
      }
      .MuiSelect-icon {
        top: calc(50% - 5px);
        right: 0;
        color: rgba(0, 0, 0, 0.54);
        position: absolute;
        pointer-events: none;
      }
    }
    .MuiButton-root-1 {
      border-radius: 5px;
      min-width: 35px;
    }
    .MuiButtonBase-root {
      vertical-align: unset;
    }
  }
  .dialog-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 30px;

    .search-cross{
      display: flex;
    }
    span {
      font-size: 16px;
      font-weight: 800;
    }
    .search-main {
      border: 1px $border-color solid;
      padding-left: 10px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      input{
        color:$medium-text;
        font-size: 14px;
      }
      svg{
        height:20px;
      }
      :hover{
        background: transparent;
      }
    }
  }
  .Toastify__toast-container--top-left {
    top: -0.2em;
    left: 0;
    width: 100%;
  }
  .Toastify__toast.Toastify__toast--success {
    padding: 20px;
  }
  .Toastify__toast--success {
    background: $dark-blue;
  }
  .Toastify__toast-container {
    padding: 0;
  }
}

.close-btn{
  background: url("../../../assets/images/close.svg") no-repeat center;
  background-size: 100%;
  height: 30px;
  padding: 0;

}
.MuiButton-root-1 {
  min-width: 30px !important;
  margin-left: 15px !important;
}

//.MuiButton-root-1:hover {
//  background-color: transparent !important;
//}

