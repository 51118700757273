// Import Colors File
@import "../../../../assets/scss/_colors.scss";
@import "../../../../assets/scss/common.scss";

.create-provider-container {
  padding: 30px;

  .create-provider-inner {
    max-width: 1170px;
    margin: 0 auto;
  }
  .single-edit-div {
    //border-bottom: 1px solid $border-color;
    padding: 0;
    margin-bottom: 10px;
    &.radio-btn {
      .MuiFormGroup-root {
        flex-direction: row !important;
        flex-wrap: wrap-reverse !important;
        label {
          width: 35%;
        }
      }
    }
    .MuiIconButton-label {
      border: 2px solid;
      border-radius: 50%;
    }
    .field-name {
      width: 100%;
    }
    .edit-input {
      width: 100%;
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px solid $border-color;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box;
    }
    .service-multiselect {
      width: 300px;
    }
    .servicetype-multiselect {
      width: 400px;
    }
  }

}

//.customMuiGridRoot{
//  //height: 100px;
//  overflow-x: hidden;
//  /* overflow-y: scroll; */
//  margin-top: 5px;
//  padding-right: 5px;
//}


.MuiButtonBase-root,.list{
  max-width: 100% !important;
  min-width: auto;
  margin: 2px!important;
}
.MuiGrid-root .MuiPaper-root{
  width: 100% !important;
  box-shadow: none!important;
  background: transparent!important;
  border: none!important;
}
