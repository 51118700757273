@import "../../../../assets/scss/colors";


.patients-section{
  .date{
    font-size:12px;
    padding:0 20px 0 0;
    width: 180px;
    display: inline-block;
    padding: 15px 50px 0px 0px;

    }
 .green-tag{
   background:#EFF8E2;
   color:#8CBA4F;
   border-radius: 20px;
   padding:0 10px 0 10px;
   font-size:14px;
 }
  .red-tag{
    background:#F2BCC3;
    color:#E98B96;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
  }
}

.baseColor {
  color: $light-blue2;
}


.flag{
  height: 17px;
  width: 17px;
  background: #0EB600;
  display: block;
  float: left;
  border-radius: 50%;
  margin: 0 6px 0 0;
}