.MuiButton-containedPrimary{
  float: right;
}
.Download-btn{
  color:white;
  float: right;
}
.tab-main-wrapper {
  /*max-width: 1600px;*/
  /*padding: 0px 96px;*/
  margin:0 auto;
  width:100%;

}
table td{
  word-break: break-all;
}
