$dark-blue: #25345c;
$faded-dark-blue: rgba(37, 52, 92, 0.7);
$blue-btn-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
$light-blue-gradient: linear-gradient(to right, #4facfe, #00c8fe);
$selected-btn: linear-gradient(189.39deg, #00c8fe 0%, #4facfe 100%);
$light-blue1: #4facfe;
$light-blue2: #00c8fe;
$light-blue3:#0091F1;
$light-text: #9ea0a5;
$green-text:green;
$light-tab: #6b6c6f;
$medium-text: #3e3f42;
$dark-text: #252529;
$nav-divider: #1d2640;
$border-color: #eaedf3;
$body-bg: #f2f2f2;
$input-border: #A4ABB3;
$left-border-hover: blue;
$hover-background: #eaedf3;
$video-btn-green:#2d9c3c;
$first-user-txt:white;
$sec-user-txt:#3e3f42;
$time-txt:#9ea0a5;
$search-icon:#9ea0a5;
$main-background:#EBEBEB;
$read-unread-heading: #25345c;
$left-chat-badge-icon: #e6492d;
$avg-border:#6aa7ee;
$search-box-border:#E2E5ED;
$dot-red:red;
$dot-green:green;
$dot-yellow:yellow;
$screens-bck:#EBEBEB;
$btn-bck:#D1D1D1;
$btn-bck-txt:#737373;
$center-txt:#A4ABB2;
$dark-green:#4CD964;
$error-state-dull: #ac0100;
$blueColor-New: #25345c;

//Login Screen
$main_blue: #25345c;
$background_grey: #EBEBEB;
$title_black: #3e3f42;
$label_grey: #6b6c6f;
$title_white: #ffffff;
$error_txt_red:red;
$text_input_box_grey:#6b6c6f;
$dark-purple:#475885;
$dark-text-heading : #1F1E25;
$dark-purple-text:#2B3A62;
$light-blue-bg:#F1F9FF;
$border-color: #E6E9EF;
