@import '../../../../../assets/scss/common';

.tab-main-wrapper {

  .tab-main-wrapper-tabs{
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }


  .MuiTab-root {
    min-width: 130px !important;
    text-align: left !important;

    .MuiTabs-scroller.MuiTabs-fixed {
      .MuiTabs-flexContainer {

      }

    }
  }
}

.MuiTab-textColorInherit.Mui-selected{
  border-bottom: 1px solid $border-color !important;
}
span.MuiTab-wrapper{

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: $light-blue3;
}
.no-content-block{
  overflow-y: auto;
  overflow-x: hidden;
  padding: 94px 60px 0px 80px;
  width: 668px;
  //padding-top: 18px;

  h3{
    color: rgb(202, 209, 224);;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.5px;

  }

  .react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }
}
.description-wrapper {
  margin-top: 30px;
  .sub-title{
    margin-top: 10px;
  }
}

.margin-top{
  margin-top: 10px !important;
}
.add-btn-wrapper{

}

.questionContentWrapper {
  display: flex;
  width:100%;
  justify-content: space-between;
}

.qustionWrapper {
  flex-basis: 35%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  .questionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px;
    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 40px;
      margin: 0;
      letter-spacing: 0.538462px;
      padding: 0;
      color: #2B3A62;
    }
    .icon-wrapper {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background: #0091f1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0;
      border: none;
      outline: none;
    }
  }
  .questionsListWrapper {
    padding: 15px;
    margin: 15px 0;
    height: calc(100vh - 141px);
    overflow-y: overlay;
    overflow-x: hidden;
    .mainQuestion {
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0px;
      }
      .questionContent {
        position: relative;
        .questionNo {
          background-color: #c1c1c1;
          position: absolute;
          left: 5px;
          top: -23px;
          z-index: 222;
          width: auto;
          padding: 0 10px;
          height: 24px;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 23px;
          letter-spacing: 0.433333px;
          border-radius: 15px;
          color: #FFFFFF;
          text-align: center;
        }
        .questionAccordionWrapper {
          padding: 10px;
          border: 1px solid #e0e0e0;
          box-shadow: none;
          border-radius: 5px !important;
          margin: 0 !important;
          &::before {
            display: none;
          }
          &.Mui-expanded {
            border-color: #0091f1 !important;
            .questionNo{
              background-color: #0091f1;
            }
          }
          .questionInner {
            min-height: auto;
            padding: 0;
            .MuiAccordionSummary-content {
              margin: 0 !important;
            }
            h4 {
              font-size: 14px;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              letter-spacing: 0.642857px;
              color: #252529;
              margin-left: 10px;
            }
            .MuiIconButton-root {
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.accordionInnerWrapper{
  display: block !important;
  padding: 0 !important;
  .questionHeader {
    padding: 10px;
    padding-top: 0;
  }
  .questionsListWrapper {
    height: inherit;
    padding: 15px 0;
    margin-bottom: 0;
  }
  .questionNo{
    background-color: #c1c1c1 !important;
  }
  .Mui-expanded{
    .questionNo{
      background-color: #0091f1 !important;
    }
  }
  .acChildInnerWrapper{
    .questionNo{
      background-color: #c1c1c1 !important;
    }
    .Mui-expanded{
      .questionNo{
        background-color: #0091f1 !important;
      }
    }
  }
}

.contentWrapper {
  flex-basis: 65%;
  .tab-main-wrapper {
    .tab-main-wrapper-tabs{
      border-top: none;
    }
  }
  .innerWrapper {
    padding: 15px;
    margin: 15px 0;
    height: calc(100vh - 109px);
    overflow-y: overlay;
    overflow-x: hidden;
    .fieldWrapper {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05) ;
      padding: 15px;
      margin-bottom: 15px;
      border: 1px solid #efefef;
      border-radius: 5px;
      .MuiFormLabel-filled, .Mui-focused{
        transform: scale(1);
      }
      label{
        font-size: 14px !important;
        margin-bottom: 5px !important;
        color: #000 !important;
        font-family: 'Roboto';
        font-weight: normal !important;
        line-height: 21px !important;
        letter-spacing: 0.642857px !important;
      }
      h4 {
        margin: 0;
        margin-bottom: 10px;
      }
      .subWrapper {
        margin-bottom: 20px;
      }
    }
    .btnWrapper {
      display: flex;
      justify-content: flex-end;
      .custom-btn {margin-left: 15px;}
    }
  }
}

.groupInnerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.groupIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #efefef;
  border-radius: 5px;
  flex-basis: 50%;
  height: 212px;
}

.groupIconWrapper .img-uploader img {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 5px;
}

.colorPickerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #efefef;
  border-radius: 5px;
  flex-basis: 50%;
  height: 212px;
  position: relative;
}
.colorPickerWrapper .MuiInput-formControl .MuiInputBase-input{
  cursor: pointer;
}
.colorPickerWrapper .MuiInput-formControl {padding: 6px 23px;background: #3f4257;border-radius: 30px;color: #fff !important;font-weight: 300;font-size: 14px;transition: all .2s ease-in;cursor: pointer;outline: none;border: none;width: 160px;margin: 20px auto;display: flex;height: 28.8px;}

.colorPickerWrapper .MuiInput-formControl:before, .colorPickerWrapper .MuiInput-formControl:after {
  display: none !important;
}
.contentWrapper .innerWrapper .fieldWrapper .colorPickerWrapper .MuiTextField-root label {
  text-align: center;
  z-index: 2;
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  margin: 0 auto;
  right: 0;
  cursor: pointer;
}
.colorBoxWrapper {
  height: 100px;
  width: 100px;
  display: block;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 5px;
}

.colorBoxInner {
  width: 100%;
  height: 100%;
  background-color: #ccc;
}

.chrome-picker {
  position: absolute;
  left: 60px;
  top: -10px;
}
