// Import Colors File
@import "../../../assets/scss/colors";


.main-form{
  padding:0 30px;
}
.check-forgot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px;
  .links {
    margin-top: 15px;
    color: $main_blue;
  }
  .MuiCheckbox-colorPrimary-64.MuiCheckbox-checked-61 {
    color: $main_blue;
  }
}

.status-para {
  padding-left: 24px;
  padding-right: 24px;
  color: $medium-text;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-align: center;
  .green {
    color: $green-text;
  }
  .red {
    color: $dot-red;
  }
}
