@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import "./colors.scss";

//.header-div {
//  height: 100px;
//  align-items: center;
//  display: flex;
//  justify-content: center;
//  position: relative;
//  .back-class {
//    position: absolute;
//    left: 20px;
//    top: 25px;
//    img {
//      width: 25px;
//    }
//  }
//  .logo-class {
//    img {
//      width: 250px;
//    }
//  }
//}
//
//.footer-div {
//  display: flex;
//  align-items: flex-end;
//  height: 60px;
//  padding: 0 30px 10px;
//  justify-content: space-between;
//  a {
//    font-size: 12px;
//    margin: 0 10px;
//  }
//  .right-most {
//    margin-right: 10px;
//    font-size: 12px;
//  }
//}


//Updated scss common

// WIDTHS

.wd-5{
  width:5%;
}
.wd-10{
  width:10%;
}
.wd-15{
  width:15%;
}
.wd-20{
  width:20%;
}
.wd-25{
  width:25%;
}
.wd-30{
  width:30%;
}
.wd-33{
  width:33.33%;
}
.wd-35{
  width:35%;
}
.wd-40{
  width:40%;
}
.wd-45{
  width:45%;
}
.wd-50{
  width:50%;
}
.wd-55{
  width:55%;
}
.wd-60{
  width:60%;
}
.wd-65{
  width:65%;
}
.wd-70{
  width:70%;
}
.wd-75{
  width:75%;
}
.wd-80{
  width:80%;
}
.wd-85{
  width:85%;
}
.wd-90{
  width:90%;
}
.wd-95{
  width:95%;
}
.wd-100{
  width:100%;
}

// MARGINS

.m-t-5{
  margin-top: 5px;
}
.m-b-5{
  margin-bottom: 5px;
}
.m-l-5{
  margin-left: 5px;
}
.m-r-5{
  margin-right: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-t-15{
  margin-top: 15px;
}
.m-b-15{
  margin-bottom: 15px;
}
.m-l-15{
  margin-left: 15px;
}
.m-r-15{
  margin-right: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-20 {
  margin-right: 20px;
}

// PADDINGS

.p-t-5{
  padding-top: 5px;
}
.p-b-5{
  padding-bottom: 5px;
}
.p-l-5{
  padding-left: 5px;
}
.p-r-5{
  padding-right: 5px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-t-15{
  padding-top: 15px;
}
.p-b-15{
  padding-bottom: 15px;
}
.p-l-15{
  padding-left: 15px;
}
.p-r-15{
  padding-right: 15px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}

//DISPLAY FLEX STYLING

.d-flex{
  display: flex;
}
.align-items-start{
  align-items: start;
}
.align-items-center{
  align-items: center;
}
.align-items-end{
  align-items: end;
}
.justify-content-start{
  justify-content: start;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-end{
  justify-content: end;
}
.flex-direction-row{
  flex-direction: row;
}
.flex-direction-column{
  flex-direction: column;
}

// Style Cheatsheet

.shadow-none{
  box-shadow: none !important;
}
.radius-none{
  border-radius: 0 !important;
}

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto;
  .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px $border-color solid;
      max-width: 600px;
      .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .main-title {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .main-form {
          .main-input-div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

//.bck-div {
//  display: flex;
//  justify-content: flex-start;
//  align-items: center;
//  position: absolute;
//  z-index: 100;
//  top: 22px;
//  .previous-btn {
//    margin-left: 20px;
//    background-color: transparent;
//    box-shadow: none;
//    img {
//      margin-right: 10px;
//      height: 25px;
//    }
//
//  }
//  .MuiButton-contained:active {
//    box-shadow: none;
//  }
//
//  .MuiButton-contained:hover {
//    background-color: transparent !important;
//  }
//
//  span {
//    font-size: 14px;
//    color: $dark-blue;
//    font-weight: 400;
//    margin-top: 2px;
//    text-transform: capitalize;
//    margin-bottom: 2px;
//  }
//}

// Main Login & Submit Button styling
.main-btn {
  display: flex;
  justify-content: center;
  .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: $main_blue;
    width: 150px;
    height: 50px;
    margin-left: 15px;
    &:hover {
      background-color: $main_blue;
    }
    .loginbtntxt {
      color: white;
    }
  }
  .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: $btn-bck;
    width: 150px;
    height: 50px;
    margin-left: 15px;
    .secbtntxt {
      color: $btn-bck-txt;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  .bar-main {
    border: 0.5px $border-color solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px;
  }
}

// Heading Text
.main-head-text {
  text-align: center;
  font-size: 18px;
  color: $dark-text;
  margin-bottom: 5px;
  font-weight: 800;
}

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: $dark-text;
  margin-bottom: 5px;
  font-weight: 800;
}

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: $medium-text;
}

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: $dark-text !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: $light-text;
  margin-bottom: 5px;
  font-weight: 500;
}

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $light-text;
}

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: $light-text;
}

.blue-bg {
  background: $light-blue-bg !important;
}

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: $light-blue1 !important;
}

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: $light-blue3 !important;
}

.small-green-txt {
  font-size: 14px;
  color: $green-text;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px;
}

.large-green-txt {
  color: $green-text;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold;
}

.dot1 {
  height: 10px;
  width: 10px;
  background-color: $light-blue1;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dot2 {
  background-color: $dark-blue;
}

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent;
}

// CH Texts styling
.titletxt {
  color: $title_black;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    color: $light-text;
    font-size: 20px;
  }
}

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif;
}

// Input
.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px $light-text solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
}

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px;

}

.txt-input:focus {
  outline: none;
  border: 1.5px $dark-blue solid;
}

//input:-webkit-autofill {
//  -webkit-text-fill-color: red !important;
//}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// Label Gray
.labeltext {
  color: $label_grey;
  margin-left: 2px;
}

// Error Text
.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px;
  .error-txt {
    color: $error_txt_red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  }
}

// No record found ( Graph )
.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }
}

.MuiAppBar-root {
  position: fixed !important;
}

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important;
  .Toastify__toast-body {
    color: #fff !important;
  }
}

.margin-bottom-5 {
  margin-bottom: 10px !important;
}

//new styles for converstation builder

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between;
  .dct-check.Mui-checked {
    color: #0091f1;
  }
  .inline-control {
    margin-right: 10px;
  }
  lable{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7;

  }
}
.firstField {
  margin-right: 10px !important;
}
.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px;
  .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0;
  }
}
.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto;
  .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999;
  }
  h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0;
  }
}

.MuiTableRow-root {
  .MuiTableCell-head {
    background: #FBFBFC;
    font-size: 12px;
  }

}

.blueColorText {
  color: $light-blue3
}

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
display: inline-block;
}

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF;

}

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal;
  .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px;
    //width: 100%;

    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%;
    }
  }
}

.MuiBreadcrumbs-separator {
  display: none !important;
}

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important;
}

li.MuiBreadcrumbs-li {
  line-height: 2px;

  .header-div {
    display: flex;
    align-items: center;
    a {
      padding: 0 10px 0 30px;
    }
  }
  a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px;
  }

}
.custom-btn-disabled {
  //background: $light-blue3 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: max-content !important;
  color: white !important;
}
.custom-btn {
  background: $light-blue3 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: max-content !important;
  color: white !important;
}

.button-with-no-bg {
  background: none;
  border: none;
  outline: none;
}

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important;
}

.remove-data {
  padding: 26px 0 0 15px;
}

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important;
}

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $light-blue3 !important;
}

.MuiRadio-root {
  color: $light-blue3 !important;
}

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
}

//.MuiInput-underline:before {
//  border-bottom: 1px solid #6F7EA7 !important;
//}

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */

  letter-spacing: 0.555556px;

  color: #181819;
}

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important;
}

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important;
}

.MuiChip-deleteIcon {
  color: $light-blue3 !important;
}

.main-body-c {
  .main-banner{
    display: flex;
    justify-content: center;
    background: #FFF5F0;
    color: #AE1824;
  }
  .draft-banner{
    display: flex;
    justify-content: center;
    background: #f0f6ff;
    color: #1854ae;
  }
  .content-main {
    max-width: 1600px;
    padding: 0 96px 20px 96px;
    margin: 0 auto;
    .title-header-main {
      background: #f1f9ffa6;
      mix-blend-mode: normal;
    }
    .table-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      height: 100px;
      > .MuiInputBase-root {
        min-width: 500px;
      }
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.714286px;
        padding: 0;
        margin: 0;
        color: #1F1E25;
      }
      .MuiFormControl-root.MuiTextField-root.search-input {
        //width: 400px !important;
        border: none !important;
        min-width: 500px !important;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.5px;
        input#input-with-icon-textfield {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
          color: $dark-purple;
        }

      }
      .table-action-filter-results {
        .MuiInput-underline:before {
          border: none !important;

        }
        .MuiInput-root {
          color: $dark-purple;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: right;
          letter-spacing: 0.7px;
        }

      }
    }

    .listing-name {

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 270px;
      display: block;

    }

  }

  .detail-page-header-main {
    background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%);
    .detail-page-header {
      margin: 0 auto;
      padding: 12px 96px 0 96px;
      max-width: 1600px;

      .details-top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 200px;
        left: 0px;
        top: 480px;
        //transform: matrix(1, 0, 0, -1, 0, 0);
        h3 {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 40px;
          letter-spacing: 0.7px;
          color: $dark-purple-text;
          .active-flag {
            height: 17px;
            width: 17px;
            background: rgba(182, 168, 177, 0.14);
            display: block;
            float: left;
            border-radius: 50%;
            margin: 10px 6px 0 0;
          }
          .active {
            background: #0EB600;
          }
        }
        h5 {
          margin: 0;
          padding: 0 0 16px 0;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          letter-spacing: 0.535714px;
          color: $dark-text-heading;

        }
        p {
          margin: 0;
          padding: 0;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: $dark-purple-text;
        }

        .value-with-bg-profile {
          background: #FFFFFF;
          box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
          border-radius: 16px;
          padding: 5px 15px;
          margin: 0 10px 0 0;
        }
      }

    }
  }

}

.chart-header-detailed {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  //padding: 0 96px 0 96px;
  height: 160px;

  .chart-header-detailed-content {

  }
  .chart-header-detailed-category {

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */
      letter-spacing: 0.5px;
      color: $light-blue1;
      span {
        height: 6px;
        width: 24px;
        border-radius: 0px;
        background: $light-blue1;
        display: inline-block;
        margin: 0 16px 0 0;
      }
    }
  }
}

// cheat sheet UI styling
.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important;
  .MuiSvgIcon-root {
    font-size: 19px;
  }
}

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0,145,241,0.35) !important;
  background-color: #f1f9ff !important;
  .MuiSvgIcon-root {
    font-size: 19px;
  }
}

.btn-wrapper {
  display: flex;
  .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 !important;
    min-width: 150px;
    .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400;
    }
  }
  .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 !important;
    min-width: 150px;
    .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400;
    }
  }
}

.padding-right-10 {
  padding: 0 10px 0 0;
}

//styles for table component

.table-component-main {
  .rdt_TableHeader {
    display: none !important;
  }
  .rdt_TableHeadRow {
    background: #f7f6f6 !important;
  }
  .rdt_TableRow {
    padding: 10px 0 10px 0 !important;
  }
}

.buttons-Wrapper {

  .edit-OutLined-btn {
    margin: 0 10px 0 0;
  }
}
.contains-col {
  color: #0091f1;
}

.MuiMenu-paper {
  min-width: 300px !important;
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px;
  }
}

//.MuiInput-underline {
//  border-bottom: none !important;
//}

//.MuiInput-underline:hover:not(.Mui-disabled):before {
//  border-bottom: none !important;
//}

//.MuiInput-underline:after {
//  border-bottom: none !important;
//}

.padding-left-20 {
  padding: 0 0 0 20px;
}
.long-text-field{
  height:80px;
  border: #e8e8e8 1px solid;
  padding: 5px;

}

.eIKzDm{
  width: 80px !important;
}
