// Import Colors File
@import "../../../../assets/scss/_colors.scss";
@import "../../../../assets/scss/common.scss";


.main-body-d {
  display: flex;
  flex-direction: column;
  height: 100%;
  .Toastify__toast-container--top-left {
    top: -0.2em;
    left: 0;
    width: 100%;
  }
  .Toastify__toast.Toastify__toast--success {
    padding: 20px;
  }
  .Toastify__toast.Toastify__toast--error {
    padding: 20px;
  }
  .Toastify__toast--success {
    background: $dark-blue;
  }
  .Toastify__toast-container {
    padding: 0;
  }

  .patient-heading-d {
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;

    span {
      color: $title_black;
      font-size: 25px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 10px;
      text-transform: uppercase;
    }
    .main-btn-sty {
      background-color: $main_blue;
      width: 150px;
      height: 50px;
      &:hover {
        background-color: $main_blue;
      }
      span {
        color: white;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-family: "Roboto", sans-serif;

      }
    }
  }

  .tab-main-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px $light-text solid;
    margin-bottom: 1px;

    .tabs-main-list{
      flexGrow: 1;
      backgroundColor: white;
    }
    .tab-scroll{
      display: flex;
      flex-direction: column;

    }
    .tab-inner-main {
      display: flex;
      flex-direction: column;
      padding: 25px;
      .no-result{
        display: flex;
        justify-content: center;
        .no-result-text{
          color: $title_black;
          font-size: 20px;
          font-weight: 500;
          font-style: normal;
          font-family: "Roboto", sans-serif;
          margin-left: 30px;
        }

      }
      align-items: center;
      .tab-inner-single{
        border:1px solid $border-color;
        margin:15px;
        padding:15px;
        display: flex;
        width: 80%;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .tab-inner-edit{
        border:1px solid $border-color;
        margin:15px;
        padding:15px;
        display: flex;
        flex-wrap: wrap;
      }
      .tab-inner-text-div{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        width: 42%;
        align-items: center;
        border-bottom: 1px solid $border-color;
        padding: 0 15px;
        .field-name{
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 26px;
          color: $title_black;
        }

        .error-msg{
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 26px;
          color: red;
          margin-left: 5px;
        }
        .field-value{
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 15px;
          color: $light-text;
          margin-left: 10px;

        }

        .edit-btn{
          margin-top: 10px;
        }
      }

      .single-edit-div {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        width: 42%;
        align-items: center;
        //border-bottom: 1px solid $border-color;
        padding: 0 15px;
        margin-bottom: 10px;
        .field-name{
          min-width: 100px;
        }
        .edit-input {
          width: 450px;
          background-color:white;
          margin-top: 2px !important;
          margin-bottom: 0 !important;
          height: 30px;
          border: 1px $border-color solid;
          padding: 8px;
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          border-radius: 4px;
          font-family: "Roboto", sans-serif;
          letter-spacing: 0.5px;
          box-sizing: content-box;
        }
      }
    }
  }


}

.Toastify {
  .Toastify__toast-container {

  }
  .Toastify__toast--success {
    background-color: rgba(43,184,38, 0.09);
    display: flex;
    justify-content: space-between;
    .Toastify__toast-body {
      color: #008a31;
    }
    .Toastify__progress-bar {
      display: none;
    }
  }
  .Toastify__toast--error {
    background-color:#E6B0AA ;
    display: flex;
    justify-content: space-between;
    .Toastify__toast-body {
      color: darkred;
    }
    .Toastify__progress-bar {
      display: none;
    }
  }
}




.section-details{
  display: flex;

}
