@import "./../assets/scss/colors";
@import "./../assets/scss/common";
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

body {
  font-family: "Open Sans", sans-serif;
}

%head {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: $light-text;
}

.main-admin-container {
  display: flex;
  width: 100%;
  //height: 100vh;
  .admin-side-nav {
    font-family: "Roboto", sans-serif;
    background-color: $dark-blue;
    //height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 610px;
    width:300px;
    .logo {
      height: 70px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-end;
      align-items: center;
      justify-content: center;

      span{
        font-family: "BrandonGrotesque",sans-serif;
        font-style: normal;
        font-size: 30px;
        color:white;
      }
    }
    .menu {
      flex: 1;
    }
    .menu-box {
      padding: 15px;
      .menu-head {
        @extend %head;
        padding-left: 16px;
      }
      .menu-list {


        color: #ffffff;
        .MuiListItem-button {
          border-radius: 4px;
          color: #ffffff;
          padding: 8px 16px;
          .MuiTypography-subheading {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
          }
          .badge-icon {
            width: 22px;
            height: 22px;
            background: #fff;
            display: flex;
            justify-content: center;
            border-radius: 15px;
            font-size: 12px;
            align-items: center;
            color: $dark-text;
            font-weight: 500;
          }
          &:hover {
            background: $selected-btn;
          }
          &.menu-selected{
            background: $selected-btn;
          }
        }
      }
    }
    .support-box {
      padding: 15px;
      color: white;
      //margin-top: 40px;
      &::before {
        height: 1px;
        content: "";
        background-color: white;
        width: 95%;
        display: block;
        //margin: 0 auto 25px;
      }
      .menu-head {
        @extend %head;
        padding-left: 16px;
      }
      .menu-list {
        .links {
          text-decoration: none;:not:hover {
          background-color: $dark-blue;
        }
        }
        .MuiListItem-button {
          border-radius: 4px;
          padding: 8px 16px;
          .MuiTypography-subheading {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
          }
          &:hover {
            background: $selected-btn;
          }
          &.menu-selected{
            background: $selected-btn;
          }
        }
      }
    }
    .user-box {
      //.MuiList-padding {
      //    padding-top: 8px !important;
      //    padding-bottom: 0 !important;
      //  }
      &::after {
        height: 1px;
        content: "";
        background-color: $nav-divider;
        width: 100%;
        display: block;
        margin: 0 auto;
      }
      .user-list {
        padding-bottom: 0 !important;
        color: white;

        .avatar-img{
          height:38px;
          width: 38px;
        }
        .MuiListItemText-primary {
          margin-left: 20px;
          color: white;
        }
        .MuiTypography-subheading {
          font-size: 14px;
          color: #ffffff;

        }
        .MuiTypography-colorTextSecondary {
          font-size: 12px;
          color: $light-text;
          margin-left: 20px;
        }
      }
      .MuiListItemSecondaryAction-root {
        top: 60%;
        right: 15px;
      }
    }
  }
  .main-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    //background: $body-bg;
    //background-color: white;
    //min-height: 650px;
    .admin-header {
      background-color: #fff;
      border-bottom: 1px solid $border-color;
      display: flex;
      height: 70px;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;
      .header-text {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: $medium-text;
      }
      .add-btn {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
        text-transform: capitalize;
        background-color: $dark-blue;
        padding: 8px 20px;
        svg {
          margin-left: 10px;
        }
      }
    }
    .body-content {
      .right-top-main {
        background-color: #fff;
        border-bottom: 1px solid $border-color;
        display: flex;
        height: 70px;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px 0 25px;
        .msg-heading {
          span{
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: $medium-text;
          }
        }
        .options-right {
          .horizontal-list {
            display: flex;
            padding: 0;
            .refresh-btn {
              border: 1px #eaedf3 solid;
              color: #ffffff;
              padding: 8px 20px;
              span {
                padding-left: 5px;
                color: grey;
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                text-transform: capitalize;
              }
            }
            .new-msg-btn {
              background-color: $dark-blue;
              padding: 8px 20px;
              span {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                text-transform: capitalize;
              }
            }
            .saved-filters-btn {
              margin-right: 20px;
              span {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: $medium-text;
              }
            }
          }
        }
      }

      .right-side-main {
        display: flex;
        //height: 90vh;
        height: calc(100vh - 75px);

        .left-main {
          background-color: white;
          //min-height: 88vh;
          //flex-grow: 1;
          background-color: white;
          //border-bottom: 1px $border-color solid;
          border-right: 1px $border-color solid;
          border-left: 1px $border-color solid;
          display: flex;
          flex-direction: column;
          max-width: 370px;
          .search-main {
            padding: 14px 20px;
            border-bottom: 0.5px $border-color solid;
            display: flex;
            flex-direction: row;

            .search-box {
              width: 500px;
              padding: 0 0 0 30px;
              border: 0.5px $search-box-border solid;
              border-radius: 5px;
              .search-text {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: $light-text;
                width: 80%;
              }
              button.MuiButtonBase-root.MuiIconButton-root.search-icon {
                color: $search-icon;
              }
            }
          }
          .left-chatlist-main {
            width: 320px;
            height: 100%;
            .unread-heading {
              padding: 20px 30px;
              border-bottom: 0.5px $border-color solid;

              span {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                text-transform: uppercase;
                color: $read-unread-heading;

              }
            }
            .recent-heading {
              padding: 20px 30px;
              border-bottom: 0.5px $border-color solid;
              span {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                text-transform: uppercase;
                color: $read-unread-heading;
              }
            }
            .left-msgs {
              span {
                //margin: 10px;
                font-size: 14px;
              }
              height: 100%;
              .no-msg {
                display: flex;
                justify-content: center;
                height: 300px;
                align-items: center;
                flex-direction: column;
                span {
                  text-align: center;
                  font-size: 16px;
                  font-weight: 100;
                  color: $dark-blue;
                  opacity: 0.6;
                }
              }
              .list-div {
                padding: 20px 30px;
                border-left: 3px white solid;
                .dot-icon-unread {
                  display: none;
                }
                &:hover {
                  background: $hover-background;
                  border-left: 3px $left-border-hover solid;
                }
                &.active {
                  background: $hover-background;
                  border-left: 3px $left-border-hover solid;
                }
                .avatar-img {
                  height: 38px;
                  width: 38px;
                  border:2px $light-blue1 solid;
                }

                .MuiListItemText-root {

                  padding: 0 0 0 15px !important;

                }
                .avatar-text {
                  width: 125px;
                  word-break: break-word;
                  .name-des {

                    .name {
                      font-family: "Roboto", sans-serif;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 24px;
                      color: $medium-text;
                      margin-right: 8px;
                    }
                    .designation {
                      font-family: "Roboto", sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 22px;
                      margin-right: 0px;
                      color: #6b6c6f;
                      word-break: break-word;
                      display: block;
                      width: 125px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                  .dot-icon {
                    align-self: center;
                    height: 6px;
                    width: 6px;
                    background-color: $dot-red;
                    border-radius: 50%;
                    display: inline-block;
                  }
                }

                .dot-status{
                  display:flex;
                  flex-direction: column;
                  margin-top: 8px;
                  margin-right: 16px;
                }
                .time-badge {
                  flex-direction: column;
                  display: flex;
                  align-items: center;
                  .time {
                    font-family: "Roboto", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    color: $time-txt;
                    line-height: 25px;
                  }
                  .badge-icon {
                    align-self: center;
                    width: 20px;
                    height: 20px;
                    background-color: $left-chat-badge-icon;
                    display: flex;
                    justify-content: center;
                    border-radius: 15px;
                    font-size: 12px;
                    align-items: center;
                    color: #fff;
                    font-weight: 500;
                  }
                }
                hr.MuiDivider-root-63.MuiDivider-inset-65 {
                  margin-left: 100px;
                }
                &.list-div-unread {
                  background-color: #eaedf3;
                  padding: 20px 15px;
                  .avatar-text {
                    width: 114px;
                  }
                  &:hover {
                    background: #eaedf3;
                    border-left: 3px transparent solid;
                  }
                  &.active {
                    background: #eaedf3;
                    border-left: 3px transparent solid;
                  }
                  .dot-icon-unread {
                    align-self: flex-start;
                    height: 8px;
                    width: 8px;
                    background-color: $dark-blue;
                    border-radius: 50%;
                    margin-right: 8px;
                    display: inline-block;
                    //margin-top: 20px;
                    //align-self: center;
                    margin-top: 20px;
                  }
                }
              }
              .recent-msg-links {
                display: flex;
                padding: 10px;
                margin-left: 100px;
                .file-des-txt {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 22px;
                  color: $light-tab;
                  padding-left: 10px;
                  margin-top: 5px;
                }
                .des-div {
                  .des-icon {
                    color: $search-icon;
                  }
                }
                .launch-div {
                  padding-left: 50px;
                }
              }
            }
          }
        }
        .right-main {

          width: 99%;
          display: flex;
          flex-direction: column;
          background-color: $body-bg;

          .avatar-menu-list-main {
            display: flex;
            background-color: white;
            padding: 15px 30px;
            align-items: center;
            justify-content: space-between;
            .avatar-div {
              .avatar-img {
                height: 38px;
                width: 38px;
                border:2px $light-blue1 solid;
              }
              .name {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: $medium-text;
                margin-right: 8px;
              }
            }
            .menu-list {
              .video-btn {
                background-color: transparent;
                border-color: transparent;
                cursor: pointer;
                min-width:50px !important;
                img{
                  margin-bottom: 8px;
                }
              }
              //.MuiButton-root-1 {
              //  min-width: 50px !important;
              //}
              .assigned-btn {
                margin-left: 20px;
                margin-top: -10px;
                background-color: $dark-blue;
                span {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 22px;
                  color: #ffffff;
                  text-transform: capitalize;
                }
              }
              .icons {
                color: grey;
                margin-left: 25px;
              }
            }
          }
          .avg-cont-main {
            background-color: white;
            padding: 10px 25px;
            display: flex;
            border-top: 0.5px $border-color solid;
            border-bottom: 0.5px $border-color solid;
            .average {
              display: flex;
              border: 2px $avg-border solid;
              padding: 16px 14px 0 11px;
              width: 50%;
              margin-right: 30px;
              .txt {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                color: $dark-blue;
              }
              .time {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                padding-left: 30px;
                color: $dark-blue;
              }
            }
            .content-search-box {
              display: flex;
              width: 50%;
              border: 0.5px $search-box-border solid;
              border-radius: 5px;
              padding: 5px 0 5px 15px;
              .content-lookup {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: $dark-blue;
                mix-blend-mode: normal;
                opacity: 0.5;
                width: 80%;
              }
              button.MuiButtonBase-root.MuiIconButton-root.search-icon {
                color: $search-icon;
              }
            }
          }
          .chat-main {
            height: calc(99vh - 219px);
            border-top: 0.5px $border-color solid;
            border-bottom: 0.5px $border-color solid;
            background-color: $body-bg;
            padding:5px 0;
            .user-one-main-main {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              .typ-icon{
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left:12px;
                margin-top: 25px;
              }
              .user-one-main {
                max-width: 500px;
                padding-left: 30px;
                padding-bottom: 30px;
                .user-typ {
                  margin-top: 10px;
                  text {
                    font-family: "Roboto", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    padding-top: 20px;
                    color: $light-text;
                  }
                  .avatar-sty {
                    height: 28px;
                    width: 28px;
                  }
                }
                .user-one-top {
                  padding: 5px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .user-avatar {
                    margin-left: -20px;
                    .name {
                      font-family: "Roboto", sans-serif;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 22px;
                      color: $medium-text;
                    }
                    .avatar-img{
                      width:28px;
                      height:28px;
                      border:2px $light-blue1 solid;
                    }

                  }
                  .user-time {
                    .time {
                      font-family: "Roboto", sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 18px;
                      color: $time-txt;
                    }
                  }
                }

                .msg-div {
                  word-wrap: break-word;
                  background-color: $dark-blue;
                  border-radius: 0px 10px 10px 10px;
                  padding: 20px;
                  span {
                    font-family: "Roboto", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    color: $first-user-txt;
                  }
                }
              }
            }
            .user-sec-main-main {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              .user-sec-main {
                max-width: 500px;
                padding-right: 30px;
                padding-bottom: 30px;
                .user-sec-top {
                  padding: 5px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .user-avatar {
                    margin-left: -20px;
                    .name {
                      font-family: "Roboto", sans-serif;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 22px;
                      color: $medium-text;
                    }
                    .avatar-img{
                      width:28px;
                      height:28px;

                    }

                  }
                  .user-time {
                    .time {
                      font-family: "Roboto", sans-serif;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 18px;
                      color: $time-txt;
                    }
                  }
                }
                .msg-div {
                  border: 1px solid $border-color;
                  box-sizing: border-box;
                  word-wrap: break-word;
                  background-color: white;
                  border-radius: 10px 0px 10px 10px;
                  padding: 20px;
                  span {
                    font-family: "Roboto", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    color: $sec-user-txt;
                  }
                }
              }
            }
            .user-main-main-sec-video {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              .user-sec-main-video {
                max-width: 500px;
                padding: 30px;
                .user-sec-top-video {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  border: 1px solid $border-color;
                  box-sizing: border-box;
                  border-radius: 10px;
                  background-color: white;
                  padding: 8px 16px;
                  .user-avatar-video {
                    margin-left: -20px;
                    .avatar-txt-video {
                      .title {
                        font-family: "Roboto", sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        color: $medium-text;
                      }
                      .sub-title {
                        font-family: "Roboto", sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        color: $light-tab;
                      }
                    }
                    .avatar-sty {
                      height: 60px;
                      width: 60px;
                    }
                  }
                  .user-button-video {
                    padding: 10px 0 0 20px;
                    text {
                      font-size: 10px;
                      color: white;
                    }
                    .refresh-btn {
                      background-color: $video-btn-green;
                    }
                  }
                }
                .msg-div {
                  padding: 5px;
                  word-wrap: break-word;
                  background-color: white;
                  span {
                    color: black;
                    font-size: 12px;
                  }
                }
              }
            }
          }
          .typing-area {
            width: 99.5%;
            background-color: white;
            display: flex;
            justify-content: space-between;
            height: 50px;
            .typing-text {
              width: 88%;
              .typing-text-style{
                width: 100%;
                padding-top: 15px;
                padding-left: 15px;
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: $dark-text;

                .MuiInputBase-inputMultiline-47 {
                  resize: none;
                  overflow: hidden;
                }

              }
            }
            .type-icons {
              //align-self: center;
              padding-top: 10px;
              .icons {
                color: $light-text;
                height: 20px;
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.dot-offline-status {
  align-self: center;
  height: 8px;
  width: 8px;
  background-color: $light-text;
  border-radius: 50%;
  display: inline-block;
}
.dot-online-status {
  align-self: center;
  height: 8px;
  width: 8px;
  background-color: $dot-green;
  border-radius: 50%;
  display: inline-block;
}

// Add content block styling to an assessment/conversation
.MuiDrawer-paperAnchorRight {
  background: #ffffff;
  border: 1px solid $border-color;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

.add-content-container {
  min-width: 700px;
  .content-header {
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
    .title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
      color: $medium-text;
    }
    .close-btn {
      background: url("../assets/images/close.svg") no-repeat center;
      background-size: 100%;
      min-width: 35px;
      height: 35px;
      padding: 0;
    }
  }
}

// Content tabs styling
.content-tabs {
  .MuiTabs-flexContainer {
    border-bottom: 1px solid $border-color;
    padding-left: 20px;
  }
  .MuiTab-root {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $light-tab;
    height: 75px;
    text-transform: capitalize;
    //min-width: 140px;
    &.MuiTab-selected {
      font-weight: bold;
      color: $medium-text;
    }
    .MuiTab-labelContainer {
      padding: 6px 15px;
    }
  }
  .MuiTabs-indicator {
    background: $selected-btn;
    height: 3px;
  }
}

// Content block styling
.tab-body {
  //padding: 30px 40px;
  .content-row {
    .c-head {
      p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: $light-tab;
        margin: 0;
      }
    }
    .c-body {
      .ch-input {
        width: 70%;
        margin-top: 8px;
        .MuiOutlinedInput-root {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $input-border;
          }
          &.MuiOutlinedInput-focused {
            .MuiOutlinedInput-notchedOutline {
              border: 1px solid $light-blue2;
            }
          }
          input {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: $dark-blue;
            padding: 13px 15px;
          }
        }
      }
    }
  }
}

//.videoWrapper {
//  .MuiListItemText-root {
//    background: url("../assets/images/video.png") no-repeat;
//    width: 25px;
//    height: 25px;
//    background-size: 100%;
//    padding: 0;
//  }
//}

.center-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  .chat-icon {
    display: flex;
    justify-content: center;
    img{
      height: 60px;
    }
  }

  span {
    font-size: 12px;
    color: $center-txt;
  }

}

//new style for conversation builder
.header-main {
  margin: 0 auto;
  padding: 12px 96px 0 96px;
  max-width: 1600px;
  .MuiToolbar-regular {
    padding: 0 !important;

    .logo{
      padding: 0 35px 0 0;
    }
    .user-drop-down-main {
      position: absolute;
      right: 0;
      //width: 121px;
      .MuiFormControl-root{
        .MuiInput-underline:before{
          border: none !important;
        }
      }
      .MuiInput-underline:after{
        border: none !important;
      }
      .user-drop-down {
        float: right;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        //text-align: right;
        letter-spacing: 0.538462px;

        color: #475885;
      }
    }
  }
}

.new-dropdown-menu {
    .dropdown-title {
      color: $dark-text;
    }
    .dropdown-title-highlighted{
      color: #0091f1;
    }
}

.breadcrumb-wrapper {
  padding: 20px 0 35px;
}

.linkSelected {
  color: black !important;
}

.drop-down-wrapper {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  .dct-check.Mui-checked {
    color: #0091f1;
  }
  .top-navigation-select-main{
    min-width: 175px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

