// Import Colors File
@import "../../../../assets/scss/_colors.scss";
@import "../../../../assets/scss/common.scss";

.create-service-container {
  padding: 30px;

  .create-service-inner {
    max-width: 600px;
    margin: 0 auto;
  }
  .single-edit-div {


    //border-bottom: 1px solid $border-color;
    padding: 0;
    margin-bottom: 10px;
    .field-name {
      width: 35%;
    }
    .edit-input {
      width: 65%;
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px $border-color solid;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box;
    }
    .dropdown {
      width: 65%;
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px $border-color solid;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box;
    }
    .service-multiselect {
      width: 300px;
    }
    .servicetype-multiselect {
      width: 400px;
    }
  }

}
