@import "../../../assets/scss/common";

.MuiFormControl-root {
  width: 100%;
}
//.customMuiGridRoot{
//  //height: 100px;
//  overflow-x: hidden;
//  /* overflow-y: scroll; */
//  margin-top: 5px;
//  padding-right: 5px;
//}


.customMuiGridRoot > .MuiButtonBase-root{
  max-width: 100%;
  margin-top: 5px!important;
  margin-right: 2px!important;
  min-width: auto;
}


.modal-wrapper {
  width: max-content;
  display: inline;
  margin: 0 0 0 10px;

}

.main-banner{
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824;
}

.modal-main {
  //margin:0 !important;
  //padding: 0 !important;

  h2{
    padding: 0 !important;
  }
  h3{
    padding: 15px 0 5px 0 !important;
    margin:0 !important;
  }
  .field-wrapper {
    padding: 0;
  }

  .modal-header {
    //padding:56px 56px 0 56px;

    .tab-main-wrapper-tabs {

      .MuiTabs-flexContainer {

        .MuiTab-textColorInherit.Mui-selected {
          border-bottom: none !important;
        }
      }
    }

  }
}

.modal-sections-main{
  display: flex;
  .importance-level-main {
    //width:50%;
    padding:0 15px 0 15px;
    .importance-level {
      //display: flex;
      overflow: hidden;
      .field-wrapper {
        padding: 0;
        float: left;
        .MuiFormControlLabel-labelPlacementStart {
          padding: 0;
          margin: 0;
        }
      }
    }
    .field-wrapper {
      padding: 0;

      .MuiFormControlLabel-labelPlacementStart {
        padding: 0;
        margin: 0;
      }
    }

  }
  .second-section{
    //width:50%;
    height: 150px;
    overflow-y: scroll;
    padding:0 15px 0 15px;
    .field-wrapper {
      padding: 0;

      .MuiFormControlLabel-labelPlacementStart {
        padding: 0;
        margin: 0;
      }
    }
    //.btn-wrapper{
    //  position: absolute;
    //  bottom: 50px;
    //  right: 40px;
    //}
  }
  .require-notes{
    display: flex;
    justify-content: space-between;
  }
}
.MuiFormControl-root{
  width:100%;
}
.modal-wrapper{
  width: max-content;
  display: inline;
  margin: 0 0 0 10px;
}

.modal-main .field-wrapper {
  margin-bottom: 24px;
}

.capitalize {
  text-transform: capitalize;
}
.form-wrapper{
  display: flex;

  input{
    width:180px !important
  }
  .MuiFormControl-root{
    max-width:50px !important;
  }
  .high-end-low-end-lable{
    width:220px !important;
    line-height: 34px;
  }


  .high-end-low-end{
    max-width:50px !important;
  }
}

.controls-main{
  display: flex;
  .custom-btn{
    display: block;
  }
  .controls-left{
    padding: 5px;
    width:50%;
  }
  .controls-right{
    padding: 5px;
    width:50%;
  }
}
.draft-banner{
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae;
}

// Domain elements Modal styling
.master-schedule-modal-content {
  min-width: 900px;
  .elements-controls-main {
    flex-direction: column;
    .elements-controls-left {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      >.field-wrapper {
        width: 36%;
        margin-right: 16px;
      }
    }
    .elements-details {
      .rx-heading {
        margin-bottom: 5px;
        font-size: 16px;
      }
      .element-fields {
        display: flex;
        flex-wrap: wrap;
        >.field-wrapper {
          width: 28%;
          margin-right: 16px;
          .MuiInput-root {
            width: 100%;
          }
          .MuiInputLabel-formControl {
            white-space: nowrap;
            width: 94%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
  .map-to-from-wrap {
    display: block;
    width: 100%;
  }
  .map-to-from {
    display: flex;
    >.MuiFormControl-root {
      width: 100%;
      margin-right: 16px;
    }
    .map-to-from {
      &.w-control {
        width: 21%;
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 35px;
      }
    }
  }
  .map-btn-wrap {
    display: flex;
    margin: 16px 0;
  }
}
.accordion-wrapper {
  margin: 24px 0;
}
.accordion-content-wrap {
  width: 100%;
}
.del-icon {
  margin-top: 16px;
}
