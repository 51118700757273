@import "../../../../assets/scss/colors";

.profile-Details-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 250px;
  left: 0;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%);
  //transform: matrix(1, 0, 0, -1, 0, 0);
  h5 {
    margin: 0;
    padding: 0 0 5px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.535714px;
    color: #1F1E25;

  }
  h3{
    &.heading {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 375px;
    }
  }
  p {
    margin: 0;
    padding: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #2B3A62;
  }

  .value-with-bg-profile {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
    border-radius: 16px;
    padding: 5px 15px;
    margin: 2px 10px 2px 0;
    display: inline-flex;
  }
  .tag-list {
    width: 560px;
    max-height: 100px;
    overflow: overlay;
    margin-right: 30px;
  }
}

.chart-header {
  padding: 0 0 40px 0;
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.714286px;
    color: $dark-text-heading;
    padding: 0;
    margin: 0 0 10px 0

  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: $dark-purple-text;
    letter-spacing: 0.535714px;
    padding: 0;
    margin: 0
  }
}

.chart-main {
  margin: 0 0 120px 0;

  .chart-content {
    display: flex;
    .chart-content-values {
      .chart-content-values-item-list {
        //width: 650px;
        li {
          display: inline-flex;
          padding:0 80px 20px 0;
          .chart-content-value-flag {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            display: block;
            margin: 0 10px 0 0;
          }
          .bluecolor{
            background:#58BDFF
          }
          .redcolor{
            background: #FF4560
          }
          .greencolor{
            background:#00E396
          }
          .yellewcolor{
            background: #FEB019;
          }
          .magcolor{
            background: #a738fe;
          }
          .chart-content-values-items {
            h4 {
              padding: 0 0 8px 0;
              margin: 0;
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.535714px;
              color: #1F1E25;
            }
            p {
              padding: 0;
              margin: 0;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.535714px;
              color: #2B3A62;
            }
          }
        }
      }
    }
  }

}

.chart-header-detailed {
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.714286px;
    color: $dark-text-heading;
    padding: 0;
    margin: 0 0 10px 0

  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: $dark-purple-text;
    letter-spacing: 0.535714px;
    padding: 0;
    margin: 0
  }

}

.baseColor {
  color: $light-blue2;
}

.main-pannel-connected-dct {

  .pannel-connected-dct-text{
    padding: 0 0 0 10px;
  }
  .dctName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block;
  }
  .accordionPannel{
    padding:10px 0 10px 0;
  }
}
