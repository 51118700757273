@import "../../../../../assets/scss/common";

.selected-content-main-wrapper {
  //padding: 45px 56px 56px 56px;
}
  .add-DCT-main {
.serach-field{
  background: #F0F2F8;
  padding:24px 0 24px 24px;
  margin: 20px 0 0 0;
  //height:68px;

  .MuiInput-underline:before{
    border:none !important;
  }
}
  .scrollabel-content {
    padding: 25px 56px 0 56px;
    height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    .field-wrapper {

    }
    .selected-dct {
      border-bottom: 1px solid #F0F2F8;;
      //height: px;
      padding: 20px 0 20px 0 !important;
      align-items: center;
      .MuiFormControlLabel-labelPlacementStart {
        height: 23px !important;
      }
      .field-wrapper-content {

        .field-wrapper-content-heading {
          display: flex;
          justify-content: space-between;
          width: max-content;
          h3 {
            padding: 0 !important;
            margin: 0 10px 7px 0 !important;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.642857px;
            color: #1F1E25;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 362px;
          }
          svg {
          }
        }
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        margin: 0;
        letter-spacing: 0.5px;
        color: #515D7D;
      }
    }
  }

    .btn-wrapper{
      padding:20px  20px 20px 56px;
    }
}
