// Import Colors File
@import "../../../../assets/scss/colors";
@import "../../../../assets/scss/common";

.main-body-pf {
  display: flex;
  flex-direction: column;
  //height: 100%;
  padding: 0 25px;
  .Toastify__toast-container--top-left {
    top: -0.2em;
    left: 0;
    width: 100%;
  }
  .Toastify__toast.Toastify__toast--success {
    padding: 20px;
  }
  .Toastify__toast.Toastify__toast--error {
    padding: 20px;
  }
  .Toastify__toast--success {
    background: $dark-blue;
  }
  .Toastify__toast-container {
    padding: 0;
  }
  .pro-credential-section {
    //display: flex;
    //flex-wrap: wrap;
  }

  .credentialBox {
    padding-left: 30px;
    padding-right: 30px;
    border-top-width: 1px;
    border-color: $dark-blue;
    padding-bottom: 24px;
    .headText {
      font-family: "Roboto", sans-serif;
      color: $dark-text;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 14px;
      letter-spacing: 0.88px;
      margin-top: 24px;
      text-transform: uppercase;
    }
    span {
      font-size: 20px;
      font-weight: 600;
    }
    .credList {
      display: flex;
      flex-direction: column;
      .singleCred {
        font-size: 14px;
        font-weight: 200;
        margin-top: 10px;
        color: $light-text;
      }

    }
  }
  .personal-info-box {
    display: flex;
    flex-wrap: wrap;
  }
  .personalInfo {
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: white;
    align-items: center;
    border-bottom-width: 1px;
    border-color: black;

    .headText {
      font-family: "Roboto", sans-serif;
      color: $dark-text;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 14px;
      letter-spacing: 0.88px;
      margin-top: 24px;
      text-transform: uppercase;
    }
    .parahText {
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 20px;
      text-align: left;
      color: $light-text;
    }

  }
}

.Toastify {
  .Toastify__toast-container {

  }
  .Toastify__toast--success {
    background-color: rgba(43, 184, 38, 0.09);
    display: flex;
    justify-content: space-between;
    .Toastify__toast-body {
      color: #008a31;
    }
    .Toastify__progress-bar {
      display: none;
    }
  }
  .Toastify__toast--error {
    background-color: #E6B0AA;
    display: flex;
    justify-content: space-between;
    .Toastify__toast-body {
      color: darkred;
    }
    .Toastify__progress-bar {
      display: none;
    }
  }
}

.single-edit-div {
  //display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  //border-bottom: 1px solid $border-color;
  padding: 0;
  margin-bottom: 10px;
  .edit-input {
    width: 100%;
    min-width: 100px;
    background-color: white;
    margin-top: 2px !important;
    margin-bottom: 0 !important;
    height: 30px;
    border: 1px $border-color solid;
    padding: 8px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.5px;
    box-sizing: content-box;
  }
  .headText {
    font-family: "Roboto", sans-serif;
    color: $dark-text;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 14px;
    letter-spacing: 0.88px;
    margin-top: 24px;
    text-transform: uppercase;
  }
}

.provider-image-wrapper {
  //display: flex;
  //flex-direction: column;
  align-items: center;
  padding: 30px;
  width:200px
;
  .profieImage {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.img-uploader {
  text-align: center;
  width: 180px;
  .fileUploader {
    .fileContainer {
      box-shadow: none;
      margin-left: 15px;
      margin-right: 15px;
      align-items: flex-start;
    }
    .chooseFileButton {
      min-width: 160px;
    }
    .uploadPicturesWrapper {
      .uploadPictureContainer {
        width: 60%;
        margin: 16px;
        .deleteImage {

        }
        .uploadPicture {

        }
      }
    }
  }
}

.update-btn {
  display: block;
  margin: 0 auto;
}

.clinicBox {
  //padding-left: 30px;
  //padding-right: 30px;
  border-top-width: 1px;
  border-color: $dark-blue;
  padding-bottom: 24px;

  .headText {
    font-family: "Roboto", sans-serif;
    color: $dark-text;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 14px;
    letter-spacing: 0.88px;
    margin-top: 24px;
    text-transform: uppercase;
  }
}
.profile-img-updater{
  .fileUploader {
    .fileContainer {
      margin:0px;
      padding: 0px;
      box-shadow: none;
      margin-left: 15px;
      margin-right: 15px;
      align-items: flex-start;
    }
    .chooseFileButton {
      min-width: 90px;
    }
    .uploadPicturesWrapper {
      .uploadPictureContainer {
        width: 60%;
        .deleteImage {

        }
        .uploadPicture {

        }
      }
    }
  }
}


.clinicBoxFieldsMain{
  display: flex;
}
.fileContainer{
  padding: 0 !important;
}
.Affliation-Wrapper{
  display: flex;
}
