@import "../../../../../../assets/scss/common";

.MuiFormControl-root {
  width: 100%;
}

.selected-content-main-wrapper {

  padding: 56px 0 56px 0;
  h2 {
    padding: 0 0 56px 0;
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.642857px;
    color: $dark-text;
  }
  .display-logics-main{

    .display-logics-text{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #515D7D;
      mix-blend-mode: normal;
      opacity: 0.75;
      padding:0 0 0 0;
      margin:0 0 40px 0;
    }
  }
  .field-wrapper {
    .MuiFormControl-root {
      label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        //letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #6F7EA7;
        padding: 0 0 10px 0;
      }
      .MuiInput-formControl {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #1F1E25;

      }
    }
  }

  .manage-choices-wrapper {
    .manage-choices-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .manage-choices-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .field-wrapper.choices {
        width: 500px;
      }
      .score {
        width: 58px;
      }
      .icon-wrapper {
        display: flex;
        i {
          padding: 0 0 0 20px;
          img{
            width: 17px;
            &[alt="Delete"] {
              width: 15px;
            }
          }
        }
      }

    }

  }
  .icon-wrapper-add-choice {
    display: flex;
    margin-bottom: 50px;
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.5px;
      display: inline;
      svg {
        padding: 0 12px 0 0
      }
    }
    .blue-text{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

  }
  .selected-content-main-wrapper-variable{
    display: flex;


    .selected-content-main-wrapper-variable-content{
      width: 100%;
      .icon-wrapper {
        height: 32px;
        width: 192px;
        padding: 0 0 40px 0;
        display: flex;
        align-items: center;
        justify-content: left;
        text-align: center;
        svg {

        }
      }
      h2{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* or 150% */
        letter-spacing: 0.642857px;
        color: #1F1E25;
      }
    }
    .selected-content-main-wrapper-variable-icon{
      width:66px;
      svg{
        width:40px;
        height:40px;
      }
    }
  }
}
