@import "../../../../assets/scss/colors";

.dct-detail-page-header-main {
  height: 250px;
  left: 0;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%);
  .detail-page-header {
    max-width: 1600px;
    padding: 0 96px 0 96px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 265px;
    .dct-details-top-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dct-details-top-content-block {
        padding: 0 60px 0 0;
        .dct-details-top-content-main-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 40px;
          color: #2B3A62;
          letter-spacing: 0.7px;
          padding: 0;
          margin: 0 0 8px 0;
          white-space: nowrap !important;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 460px !important;
        }
        .dct-details-top-content-main-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          padding: 0;
          margin: 0;
          color: #2B3A62;
        }
        .dct-details-top-content-heading {

          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 20px 0 16px 0;
          letter-spacing: 0.535714px;
          color: #1F1E25;
        }
        .dct-details-top-content-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #2B3A62;
        }
      }

    }
    .dct-Details-top-actions {

    }
  }
}

.content-block-main {
  display: flex;
  height: 100vh;
  margin:0 0 50px 0 ;
  .content-block-8blocks {
    max-width: 500px;
    width: 500px;
    height: inherit;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: scroll;
    overflow-x: hidden;
    .content-block-8blocks-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        margin: 26px 0 22px 24px;
        letter-spacing: 0.538462px;
        padding: 0;
        color: #2B3A62;
      }
      .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0091F1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 24px 0 0;
        border: none;
        outline: none;

        svg {
          margin: 0 auto;
        }

      }
    }
    .content-block-8blocks-item {
      display: flex;
      padding: 33px 0 25px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px;
        svg {
          margin: 0 auto;
        }
      }
      .content-block-8blocks-item-content {
        p {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.535714px;
          color: #25345C;
          padding: 0;
          margin: 0 0 16px 0;
        }
        .content-block-8blocks-item-content-text {
          display: flex;
          padding: 0 0 8px 0;
          h4 {
            padding: 0;
            margin: 0;
            width: 292px;
            //white-space: nowrap;
            //overflow: hidden;
            //text-overflow: ellipsis;
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.642857px;
            color: #1F1E25;
          }
          span {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 13px;
            line-height: 15px;
            text-align: right;
            letter-spacing: 0.464286px;
            width: 46px;
            padding: 0 10px 0 22px;
            color: #8F9EAE;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .content-block-8blocks-item-content-footer {
          display: flex;
          horiz-align: center;
          justify-content: space-between;
          //width: 68px;
          .MuiSvgIcon-root{
            font-size: 20px;
          }
          .link-icon {
            transform: rotate(140deg);
          }
          .block-icon {
              padding: 0 5px 0;
        }
          h5 {
            padding: 0;
            margin: 0;
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #515D7D;
            mix-blend-mode: normal;
            opacity: 0.75;
          }
          .icons {
            padding: 0 4px 0 0;
          }
        }
      }
    }
  }

  .content-block-selected-block {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 94px 20px 0px 20px;
    //width: 668px;
    .content-block-selected-block-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:0 0 24px 0;
      h3{
        padding: 0;
        margin:0;

        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0.538462px;
        color: #2B3A62;
      }
      .icon-wrapper{
        i{
          padding: 0 0 0 24px;
        }
      }
    }

    .MuiTab-wrapper {
      align-items: flex-start !important;
    }
  }
}

.patients-section{
  .date{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #515D7D;
    width: 180px;
    display: inline-block;
  }


  .main-tag{
    border-radius: 20px;
    padding: 0 10px 0 10px;
    font-size: 14px;
    width: 123px;
    display: block;
    float: right;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.5px;
    text-align: center;
  }
  .green-tag{
    background:#EFF8E2;
    color:#8CBA4F;
  }
  .orange-tag{
    background: rgba(255, 143, 2, 0.1);
    color: #FF8F02;
  }
  .red-tag{
    background:#F2BCC3;
    color:#E98B96;
  }
}

