//@import "../../../../../assets/scss/colors";
.line-chart{
  .wrapper{
    .legend-wrapper{
      .legend-item:nth-child(1){
        .legend-icon{
          height:5px;
          width:12px;
          border-radius: 50%;
          margin: 0 20px 0  0 ;
          fill:#A7DB63 !important;
        }
        .legend-label{
          fill:#A7DB63 !important;
        }

      }
      .legend-item:nth-child(2){
        .legend-icon{
          height:5px;
          width:12px;
          border-radius: 50%;
          margin: 0 20px 0  0 ;
          fill:#E05C6E !important
        }
        .legend-label{
          fill:#E05C6E !important
        }
      }
    }
  }
}
