@import "../../../assets/scss/common";

.MuiTableCell-alignLeft{
  .service-type-desc{
    display: -webkit-box;
    //max-width: 380px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    //width: 390px;
    white-space: unset;
    text-overflow: ellipsis;
  }

}

.main-body-c {
  margin: 30px 0 0 0 ;
}

