@import "../../../../assets/scss/common";


.patients-section{
  .date{
    font-size:12px;
    padding:0 20px 0 0;
  }
  .green-tag{
    background:#EFF8E2;
    color:#8CBA4F;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
  }
  .red-tag{
    background:#F2BCC3;
    color:#E98B96;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
  }
}

.baseColor {
  color: $light-blue2;
  cursor: pointer;
}


.flag{
  height: 17px;
  width: 17px;
  //background: #0EB600;
  display: block;
  float: left;
  border-radius: 50%;
  margin:15px 6px 0 0;
}
.dct-details-top-content-block {
  padding:0px 42px 0px 0px !important;
  .dct-details-top-content-main-heading {
    &.heading {
      text-transform: capitalize !important;
      width: 375px !important;
      white-space: normal !important;
    }
  }
}