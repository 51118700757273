// Import Colors File
@import "../../../assets/scss/_colors.scss";
@import "../../../assets/scss/common.scss";

.dark-text-large {

}

.service-category-text {
  font-weight: bolder;
  font-size: 20px;
}
.appt-table {
  max-width: 700px;
  margin: 20px 0;
  width: 100%;
  .schedule-row {
    //border-bottom: 1px solid #E2E5ED;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    .del-svc-btn {
      float: right;
      background-color: $error-state-dull !important;
      border-radius: 15px !important;
      width: 100px;
      height: 40px;
      color: white !important;
      font-size: 15px;
      margin: 0px 10px !important;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      &:hover {
        background-color: $error_txt_red !important;
        color: white;
      }
    }
  }
  .schedule-left {
    text-align: left;
    .main-head-text {
      text-align: left;
    }
    .light-text-medium {

    }
  }
  .schedule-right {
    display: flex;
  }
}

.no-result{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .no-result-text{
    color: $title_black;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    margin-left: 30px;
  }

}

.MuiGrid-container {
  margin: 0 !important;
  width: 100% !important;
}

.schedule-left-sericeType{
  //display: flex;
}
.service-type-chip{
  margin:2px;
}