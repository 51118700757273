// Import Colors File
@import "../../../../assets/scss/_colors.scss";
@import "../../../../assets/scss/common.scss";

.main-body-pf {
  display: flex;
  flex-direction: column;
  //height: 100%;
  padding: 0 25px;
  .Toastify__toast-container--top-left {
    top: -0.2em;
    left: 0;
    width: 100%;
  }
  .Toastify__toast.Toastify__toast--success {
    padding: 20px;
  }
  .Toastify__toast.Toastify__toast--error {
    padding: 20px;
  }
  .Toastify__toast--success {
    background: $dark-blue;
  }
  .Toastify__toast-container {
    padding: 0;
  }
  .patient-heading-d {
    display: flex;
    justify-content: flex-start;
    padding: 30px 0 30px 0 !important;
    .profieImage{
      padding-left:25px;
      padding-right: 25px;
      padding-bottom: 25px;
    }
    span {
      color: $title_black;
      font-size: 25px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 10px;
      text-transform: uppercase;
    }
    .main-btn-sty {
      background-color: $main_blue;
      width: 150px;
      height: 50px;
      &:hover {
        background-color: $main_blue;
      }
      span {
        color: white;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-family: "Roboto", sans-serif;
      }
    }
  }

  //.credentialBox {
  //  padding-left: 30px;
  //  padding-right: 30px;
  //  border-top-width: 1px;
  //  border-color: $dark-blue;
  //  padding-bottom: 24px;
  //  span {
  //    font-size: 20px;
  //    font-weight: 600;
  //  }
  //  .credList {
  //    display: flex;
  //    flex-direction: column;
  //    .singleCred {
  //      font-size: 14px;
  //      font-weight: 200;
  //      margin-top: 10px;
  //      color: $light-text;
  //    }
  //
  //  }
  //}

  .clinicBox {
    border-top-width: 1px;
    border-color: $dark-blue;
    padding-bottom: 24px;

    .headText {
      fontFamily: 'Roboto-Bold';
      color: $dark-text;
      font-weight: 600;
      fontSize: 20px;
      marginBottom: 10px;
      lineHeight: 14px;
      letterSpacing: 0.88px;
      marginTop: 24px;
      alignSelf: 'flex-start';
      textTransform: 'uppercase';
    }

    //span {
    //  font-size: 20px;
    //  font-weight: 600;
    //  margin-bottom: 16px;
    //  display: block;
    //}
    //
    //.singleClinic {
    //  flexDirection: 'row';
    //  marginTop: 10px;
    //
    //  .clinicImg {
    //    marginRight: 16px;
    //    resizeMode: 'cover';
    //  }
    //  .clinicInfo {
    //    display: flex;
    //    flex-direction: column;
    //
    //    .institute {
    //      color: $light-text;
    //      font-size: 13px;
    //      line-height: 25px;
    //      letter-spacing: 0.43px;
    //      font-family: 'Roboto-Regular';
    //      margin-bottom: 5px;
    //    }
    //
    //    .cliName {
    //      color: $light-text;
    //      font-size: 14px;
    //      letter-spacing: 0.47px;
    //      font-family: 'Roboto-Bold';
    //      margin-bottom: 2px;
    //    }
    //
    //    .cliLocation {
    //      color: $light-text;
    //      font-size: 13px;
    //      letter-spacing: 0.28px;
    //      font-family: 'Roboto-Regular';
    //    }
    //    .cliDate {
    //      color: $light-text;
    //      font-size: 13px;
    //      letter-spacing: 0.28px;
    //      font-family: 'Roboto-Regular';
    //    }
    //  }
    //
    //}
  }

  //.eduBox {
  //  padding-left: 30px;
  //  padding-right: 30px;
  //  padding-bottom: 24px;
  //  border-top-width: 1px;
  //  border-color: $border-color;
  //
  //  span {
  //    font-size: 20px;
  //    font-weight: 600;
  //    margin-bottom: 16px;
  //    display: block;
  //  }
  //
  //  .singleEdu {
  //    flexDirection: 'row';
  //    marginTop: 10px;
  //    marginBottom: 15px;
  //
  //    .eduInfo {
  //      display: flex;
  //      flex-direction: column;
  //      .eduName {
  //        color: $light-text;
  //        font-size: 14px;
  //        line-height: 22px;
  //        letter-spacing: 0.47px;
  //        font-family: 'Roboto-Bold';
  //        margin-bottom: 5px;
  //      }
  //      .institute {
  //        color: $light-text;
  //        font-size: 13px;
  //        line-height: 25px;
  //        letter-spacing: 0.43px;
  //        font-family: 'Roboto-Regular';
  //        margin-bottom: 5px;
  //      }
  //      .cliDate {
  //        color: $light-text;
  //        font-size: 13px;
  //        letter-spacing: 0.28px;
  //        font-family: 'Roboto-Regular';
  //      }
  //      .eduDes {
  //        color: $light-text;
  //        font-size: 13px;
  //        line-height: 21px;
  //        font-family: 'Roboto-Regular';
  //      }
  //    }
  //  }
  //}


  .personal-info-box {
    display: flex;
    flex-wrap: wrap;
  }

  .personalInfo {
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: white;
    align-items: center;
    border-bottom-width: 1px;
    border-color: black;

    .headText {
      fontFamily: 'Roboto-Bold';
      color: $dark-text;
      font-weight: 600;
      fontSize: 14px;
      marginBottom: 10px;
      lineHeight: 14px;
      letterSpacing: 0.88px;
      marginTop: 24px;
      alignSelf: 'flex-start';
      textTransform: 'uppercase';
    }
    .parahText {
      fontFamily: 'Roboto-Bold';
      fontSize: 15px;
      lineHeight: 22px;
      marginBottom: 20px;
      textAlign: 'left';
      alignSelf: 'flex-start';
      color: $light-text;
    }

  }
}

.Toastify {
  .Toastify__toast-container {

  }
  .Toastify__toast--success {
    background-color: rgba(43, 184, 38, 0.09);
    display: flex;
    justify-content: space-between;
    .Toastify__toast-body {
      color: #008a31;
    }
    .Toastify__progress-bar {
      display: none;
    }
  }
  .Toastify__toast--error {
    background-color: #E6B0AA;
    display: flex;
    justify-content: space-between;
    .Toastify__toast-body {
      color: darkred;
    }
    .Toastify__progress-bar {
      display: none;
    }
  }
}


.main-list-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 24px;

  .list-head {
    fontFamily: 'Roboto-Bold';
    color: $dark-text;
    font-weight: 600;
    fontSize: 14px;
    marginBottom: 10px;
    lineHeight: 14px;
    letterSpacing: 0.88px;
    marginTop: 24px;
    alignSelf: 'flex-start';
    textTransform: 'uppercase';
  }

  .main-list-div {
    display: flex;
    flex-direction: column;
    .list-text {
      color: $light-text;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.47px;
      font-family: 'Roboto-Bold';
      margin-bottom: 5px;
    }
  }
}

.main-date-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 24px;
  .date-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dateText {
      fontFamily: 'Roboto-Bold';
      color: $dark-text;
      font-weight: 600;
      fontSize: 14px;
      marginBottom: 10px;
      lineHeight: 14px;
      letterSpacing: 0.88px;
      marginTop: 24px;
      alignSelf: 'flex-start';
      textTransform: 'uppercase';
    }
    .cliDate {
      color: $light-text;
      font-size: 13px;
      letter-spacing: 0.28px;
      font-family: 'Roboto-Regular';
      margin-left: 10px;
    }
  }
}
h2 {
  &.parahText {
    font-weight: 400;
    font-size: 14px;
  }
}
.clinicBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  p {
    margin-top:0px;
  }
  .main-list-wrapper,
  .main-date-wrapper {
    padding-left: 0px;
  }
}
table {
  .MuiTableRow-root {
    width: 100%;
    display: inline-flex;
  }
  tbody {
    max-height: 520px;
    overflow-y: auto;
    display: block !important;
  }
  th, td {
    width: 25%;
  }
}
