

@import "../../../../../assets/scss/_colors.scss";
@import "../../../../../assets/scss/common.scss";


::-webkit-scrollbar,
.modal::-webkit-scrollbar{
  width: 6px;
  height: 6px;
}
.clickable-link {
  font-size: 14px;
  color: $light-blue1 !important;
  cursor: pointer;
}
::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(170, 170, 170, 0.15)
}

::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover,
.modal::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

.title-header-main .title-header{
  height: auto !important;
}
.main-container-appointments {

  margin:0 auto;
.main-body-appointments {
  padding: 15px 96px;
  max-width: 1600px;
  margin: 0 auto;
}
.table-mid{
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  transition: all .4s ease-in-out 0s;
  box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08), 0 2px 5px 1px rgba(69, 65, 78, 0.04);
}
.table-component-main {
//padding:15px;
//border-radius: 5px;
//background-color: #fff;
//transition: all .4s ease-in-out 0s;
//box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08), 0 2px 5px 1px rgba(69, 65, 78, 0.04);
.rdt_Table {
.rdt_TableHeadRow{
  min-height: 40px;
  border-bottom: 1px solid rgba(0,0,0,.12);
  border-top: 1px solid rgba(0,0,0,.12);
  background-color: transparent !important;
.rdt_TableCol{
  font-weight: 600;
  font-size: 13px;
  color: #252529;
}
}
.sc-bBHwJV, .sc-dlVyqM {
  height: calc(100vh - 317px);
}
.rdt_TableBody {
  height: calc(100vh - 373px);
  overflow: overlay;
  margin:10px 0px;
.rdt_TableRow{
&:hover{
   background-color: rgba(240, 248, 255, 0.50);
 }
rdt_TableCell{
  font-weight: 400;
  color: #333;
}
}
}

}
.rdt_Pagination {
  min-height: 30px;
  padding-top: 10px;
.kFZtQx {
  width: 24px;
  height: 24px;
  padding: 0px;
}
}
}
.dropdown-select{
.MuiInput-root{
  min-width: 100px;
.MuiSelect-select {
  color: #252529 !important;
  font-weight: 400;
  font-size: 14px;
&:focus{
   background-color: transparent;
 }
}
.MuiSelect-icon{
  color: #252529;
  right:-5px;
}
&:before{
   border-bottom-color: #252529;
 }
&:after{
   border-bottom: 1px solid #133590;
 }
&:hover{
&:not(.Mui-disabled):before{
   border-bottom: 1px solid #00c2ff;
 }
.MuiSelect-icon,
.MuiSelect-select {
  color: #00c2ff !important;
}
}
}
}
}
.MuiPopover-paper{
  box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08), 0 2px 5px 1px rgba(69, 65, 78, 0.04) !important;
.MuiMenu-list{
.MuiListItem-button {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #252529 !important;
&:hover{
   background-color: #00c2ff;
   color: #fff !important;;
 }
}
.Mui-selected{
  background-color: #00c2ff;
  color: #fff !important;
}
}
}
