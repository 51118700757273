// Import Colors File
@import "../../../../assets/scss/colors";
@import "../../../../assets/scss/common";


.main-body-search-comp {
  display: flex;
  flex-direction: column;
  min-height: 580px;

  .sub-title{
    color: $title_black;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    margin-left: 30px;
    margin-top: 20px;
  }
  .no-result{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .no-result-text{
      color: $title_black;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 30px;
    }

  }

  .patient-heading {
    display: flex;
    justify-content: flex-start;
    padding: 0 30px;

    span {
      color: $title_black;
      font-size: 25px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 10px;
    }
    .main-btn-sty {
      background-color: $main_blue;
      width: 150px;
      height: 50px;
      &:hover {
        background-color: $main_blue;
      }
      span {
        color: white;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-family: "Roboto", sans-serif;

      }
    }
    .search-main {
      margin-left: 50px;
      .debounce-input{
        min-width: 300px ;
        padding : 10px 16px;
      }
    }
  }
  .no-result-main{
    .no-record{
      text-align: center;
    }

  }

  .list-main-wrapper {
    display: flex;
    flex-direction: column;
    margin: 25px;
    border: 1px solid $search-box-border;
    //border-top: 1px $dark-blue solid;
    //border-right: 1px $dark-blue solid;
    //border-left: 1px $dark-blue solid;
    .list-main {
      padding: 0;
      border-bottom: 1px $search-box-border solid;
    }


  }

}
.user-authority {
  color: grey;
  float: right;
  margin-right: 200px;

}


.search-main {
  display: flex;
  justify-content: center;
}

.search-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  width: 400px;
  .global-search-input {
    flex: 1;
    padding-left: 10px;

  }
  .global-search-icon-btn {
    padding: 10px,
  }
}


