// Import Colors File
@import "../../../assets/scss/_colors.scss";
@import "../../../assets/scss/common.scss";

.main-body-schedule {
  padding: 0 96px;
  max-width: 1600px;
  margin:0 auto;
  .dct-check {
    &.Mui-checked {
      color: #0091f1;
    }
  }
  .days {
    margin-top: 30px !important;
  }
  .custom-btn {
    margin: 13px;
  }
  .appointments {
    display: inline-block;
    width: 100%;
    clear: both;
    margin: 50px 0;
    .text-align {
      text-align: right;
    }
  }
  .fade {
    color: #848488;
  }
}
/** Custom Styling */

//.customMuiGridRoot{
//  //height: 100px;
//  overflow-x: hidden;
//  /* overflow-y: scroll; */
//  margin-top: 5px;
//  padding-right: 5px;
//}

//.customMuiGridRoot > .MuiButtonBase-root{
//  max-width: 100%;
//  margin-top: 5px!important;
//  margin-right: 2px!important;
//  min-width: auto;
//}

