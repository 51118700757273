@import "../../../assets/scss/colors";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .inner-container {
    color: $dark-blue;
    font-family: 'Open Sans', sans-serif;
    margin-top: 100px;
    max-width: 340px;
    text-align: center;
    .nf-img {
      width: 140px;
    }
    .nf-head {
      font-weight: 600;
      font-size: 26px;
      margin-bottom: 0;
    }
    .nf-parah {

    }
    .nf-btn {
      border: none;
      background: $dark-blue;
      color: #fff;
      padding: 15px 40px;
      margin-top: 20px;
      border-radius: 5px;
      font-family: 'Open Sans', sans-serif;
      outline:none;
    }
  }
}