// Import Colors File
@import "../../../assets/scss/_colors.scss";
@import "../../../assets/scss/common.scss";

.main-body-pf {
  //height: 100vh;
  padding: 0 80px !important;
  max-width: 1600px;
  margin: 0 auto;
  .list-main-wrapper {
    margin-top: 10px;
  }
}
.svc-scroll {
  height: 100vh;
}
.provider-action {
  float: right;
  background-color: $avg-border !important;
  border-radius: 15px !important;
  width: 170px;
  height: 50px;
  color: white !important;
  font-size: 15px;
  margin: 0px 10px !important;
  font-weight: 600;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  &:hover {
    background-color: $light-blue1 !important;
    color: white;
  }


}

.name-des{
  display:flex;
  justify-content: space-between;
  .name{
    padding:8px
  ;
  }
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.custom-btn.top-section-button.MuiButton-containedPrimary {
  padding: 10px;
}