@import "../../../../assets/scss/colors";


.patients-section{
  .date{
    font-size:12px;
    padding:0 20px 0 0;
    width: 180px;
    display: inline-block;
    padding: 15px 50px 0px 0px;


    }
 .green-tag{
   background:#EFF8E2;
   color:#8CBA4F;
   border-radius: 20px;
   padding:0 10px 0 10px;
   font-size:14px;
 }

  .orange-tag{
    background: rgba(255, 143, 2, 0.1);
    color: #FF8F02;
   border-radius: 20px;
   padding:0 10px 0 10px;
   font-size:14px;
 }
  .red-tag{
    background: #f6e2f8;
    color: #af4fba;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
  }


}

.baseColor {
  color: $light-blue2;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button{
  //padding: 25px 0 25px 0;
  border-bottom: 1px solid #F0F2F8;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  //text-align: right;
  letter-spacing: 0.535714px;

  color: #0091F1;
}

.flag{
  height: 17px;
  width: 17px;
  background: #0EB600;
  display: block;
  float: left;
  border-radius: 50%;
  margin: 0 6px 0 0;
}

.dct-Report-main{
  .dct-Report-main-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dct-Report-main-header-info {

      h4{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.714286px;
        color: #1F1E25;
        padding: 0 0 10px 0;
        margin: 0;
      }
      p{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        padding: 0;
        margin: 0;
        letter-spacing: 0.535714px;

        color: #2B3A62;
      }
    }
    .dct-Report-main-header-tag{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dct-assign-shape{
        width:24px;
        height:6px;
        background: $light-blue1 !important;
      }
      p{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        padding:0 0 0 9px;
        letter-spacing: 0.5px;

      }
    }
  }
}

.main-tag{
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: capitalize !important;
}

.Dct-Report-Period-Select {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;


  width: 60px !important;
}

.Period-Filter {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  align-items: baseline;


  width: 200px !important;
}



.filtered-values{
  float: right;
  .MuiPaper-elevation1{
    background-color: transparent;
    padding: 20px  5px 0 5px;
    box-shadow: none;
  }
}

.filter-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 500px;
  .global-search-input {
    flex: 1;
    padding-left: 10px;

  }
  .global-search-icon-btn {
    padding: 10px
  }
}

.dct-status-select {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;


  width: 400px !important;
}