// Import Colors File
@import "../../../../assets/scss/_colors.scss";
@import "../../../../assets/scss/_common.scss";

.values {
  float: left;
  background-color: #f2f9ff;
  border-radius: 25px !important;
  color: $label_grey;
  font-size: 15px;
  font-style: normal;
  padding: 10px;

}

.button {
  float: left;
  background-color: #f2f9ff !important;
  border-radius: 2px !important;
  border-left-width: thin !important;
  border-color: #0079ff !important;
  color: #0079ff !important;
  margin: 5px !important;

  &:hover {
    background-color: $title_white !important;
    color: black !important;
  }
}

.appearsInText {
  color: $light-blue2 !important;
  font-size: 15px;
  font-weight: 600;
}

.MuiButton-textPrimary {
  color: #0091f1 !important;
}

.create-service-container {
  padding: 30px;

  .create-service-inner {
    max-width: 600px;
    margin: 0 auto;
  }
  .single-edit-div {

    //border-bottom: 1px solid $border-color;
    padding: 0;
    margin-bottom: 10px;
    .field-name {
      width: 35%;
    }
    .edit-input {
      //width: 65%;
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px $border-color solid;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box;
    }

    .profileElementType-multiselect {
      padding-left: 0px !important;
      align-content: flex-start;
      justify-content: flex-start;
      justify-self: flex-start;

      width: 400px !important;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none !important;
    }
    .MuiInput-underline:before {
      border-bottom: none !important;
    }
    .profileElementMethod-multiselect:before {
      border-bottom: none !important;
    }
    .profileElementMethod-multiselect {
      padding-left: 0px !important;
      width: 400px !important;

    }
    .menuItem {
      padding-left: 0px !important;
      display: inline-flex;
      justify-content: flex-start;
    }
  }

}

.root {
  display: inline-flex;
  justify-content: flex-start;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  border: 1px $border-color solid;
  padding: 4px;
  box-sizing: content-box;
  width: 400px;
}

.chip {
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
}

.list {
  list-style-type: none;
}

.save-btn-txt {
  color: white;
}

.search-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 400px;
  .global-search-input {
    flex: 1;
    padding-left: 10px;

  }
  .global-search-icon-btn {
    padding: 10px
  }
}

.filter-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 500px;
  .global-search-input {
    flex: 1;
    padding-left: 10px;

  }
  .global-search-icon-btn {
    padding: 10px
  }
}

.search-main {
  .search-input {
    //width: 400px !important;
    border: none !important;
    min-width: 500px !important;
  }
  .search-paper-root {
    box-shadow: none;
    //border-bottom: 1px solid #475885;
    border-radius: 0;
    input {
      font-size: 12px;
      width: 234px;
    }
  }
}

.MuiSelect-select.MuiSelect-select {
  color: #0091f1 !important;
}

.content-main {
  .filtered-values {
    float: right;
    .MuiPaper-elevation1 {
      background-color: transparent;
      padding: 20px 5px 0 5px;
      box-shadow: none;
    }
  }
}

@media screen and (max-width:1367px) {

  .bMYmCz{
    max-width: 190px !important;
  }
}
.dJshAA div:first-child{
  white-space: break-spaces !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.value-with-bg{
  margin: 2px;
}
