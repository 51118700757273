// Import Colors File
@import "../../../../assets/scss/_colors.scss";
@import "../../../../assets/scss/common.scss";


.main-body-sc {
  display: flex;
  flex-direction: column;

  .sub-title{
    color: $title_black;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    margin-left: 30px;
    margin-top: 30px;
    align-self: center;
  }
  .no-result{
    display: flex;
    justify-content: center;
    .no-result-text{
      color: red;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-top: 30px;
    }

  }

  .patient-heading {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;

    span {
      color: $title_black;
      font-size: 25px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 10px;
    }
    .main-btn-sty {
      background-color: $main_blue;
      min-width: 150px;
      height: 50px;
      &:hover {
        background-color: $main_blue;
      }
      span {
        color: white;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-family: "Roboto", sans-serif;

      }
    }
  }

  .list-main-wrapper {
    display: flex;
    flex-direction: column;
    margin: 25px;
    border: 1px solid $search-box-border;
    //border-top: 1px $dark-blue solid;
    //border-right: 1px $dark-blue solid;
    //border-left: 1px $dark-blue solid;

    .no-result{
      display: flex;
      justify-content: center;
      .no-result-text{
        color: red;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-family: "Roboto", sans-serif;
        margin: 30px;
      }

    }

    .list-main {
      padding: 0;
      border-bottom: 1px $search-box-border solid;



      .avatar-text{
        display: flex;
        justify-content: space-between;
      }
    }


  }

  .search-cross {
    display: flex;

    .search-main {
      display: flex;
      flex-direction: row;

      .search-box {
        width: 400px;
        padding: 0 0 0 30px;
        border: 0.5px $search-box-border solid;
        border-radius: 5px;
        .search-text {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: $light-text;
          width: 80%;
        }
        button.MuiButtonBase-root.MuiIconButton-root.search-icon {
          color: $search-icon;
        }
      }
    }
  }

}




