// Import Colors File
@import "../../../assets/scss/colors";


.main-login-container {
  width: 40%;
  .login-header {
    font-size: 19pt;
    text-transform: uppercase;
  }
  .main-form{
    width: 100%;
    padding:1px 30px;

    .btn-right {
      float: right;
    }
  }
}

.check-forgot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px;
  .links {
    margin-top: 15px;
    color: $main_blue;
  }
  .MuiCheckbox-colorPrimary-64.MuiCheckbox-checked-61 {
    color: $main_blue;
  }
}
